<!-- 培训班级类别列表编辑 -->
<template>
    <div class="EditList">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训班级类别列表编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="编码" >
                <el-input v-model="form.codeNum" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="培训科目" prop="subject">
                <el-select v-model="form.subject"  placeholder="请选择培训科目" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="主责老师" >
                <el-select v-model="form.principal" filterable placeholder="请输入并选择主责老师" :clearable="true">
                    <el-option v-for="item in form.principalOpt" :key="item.id" :label="item.fullname" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="授课次数" >
                <el-input v-model="form.frequency" placeholder="请输入授课次数"></el-input>
            </el-form-item>
            <el-form-item label="培训班名称" prop="className">
                <el-input v-model="form.className" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.conditionChose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="启用日期">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.enableDate"
                    type="date"
                    placeholder="请选择启用日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="停用日期">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.stopDate"
                    type="date"
                    placeholder="请选择停用日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="每班人数">
                <el-input v-model="form.peopleNum" placeholder="" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="学杂费标准(元/人)">
                <el-input v-model="form.outlay" placeholder="" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="其中学费(元/人)">
                <el-input v-model="form.tuition" placeholder="" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="杂费(元/人)">
                <el-input v-model="form.incidental" placeholder="" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" class="textArea-width" v-model="form.explain" 
                placeholder=""></el-input>
            </el-form-item>
        </el-form>
           
        <el-divider></el-divider>
        
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" 
              @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form:{
                   codeNum:"",//编码
                   subject:"",//培训科目
                   subjectOpt:[],//科目数据
                   principal:"",//主责老师
                   principalOpt: [], //主责老师选项
                   frequency:"",//授课次数
                   className:"",//培训班名称
                   condition:"1",//状态
                   conditionChose:[{
                            value: '1',
                            label: '准备'
                        },
                        {
                            value: '2',
                            label: '可用'
                        },
                        {
                            value: '3',
                            label: '停用'
                        }
                   ],//状态选项
                   enableDate:"",//启用日期
                   stopDate:"",//停用日期
                   peopleNum:"",//每班人数
                   outlay:"",//学杂费标准
                   tuition:"",//其中学费
                   incidental:"",//杂费
                   explain:""//说明
                },
                rules:{
                   subject:[
                       { required: true, message: '培训科目不能为空', trigger: 'blur' },
                   ],
                   principal:[
                       { required: true, message: '主责老师不能为空', trigger: 'blur' },
                   ],
                   frequency:[
                       { required: true, message: '授课次数不能为空', trigger: 'blur' },
                   ],
                   className:[
                       { required: true, message: '培训班名称不能为空', trigger: 'blur' },
                   ],
                },
                bool:false
            }
        },
        created() {
            // 培训科目数据
            this.$request({
                url: '/api/course/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log(res, "培训科目")
                this.form.subjectOpt = res.data.list
            })
            
            // 主责老师数据
            this.$request({
                url: '/api/humanresources/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log(res, "主责老师")
                this.form.principalOpt = res.data.list
            })
            
            // 初次渲染
            this.$request({
                url: '/api/classcategory/detail',
                method: 'POST',
                data: {
                    id:this.$route.query.id
                }
            }).then(res => {
                console.log(res, "初次渲染")
               if(res.code==1){
                   let form = this.form
                   let data = res.data
                   form.codeNum=data.number//编码
                   form.subject=data.course_id//培训科目
                   form.principal=data.resources_id //主责老师
                   form.frequency=data.lecture_number//授课次数
                   form.className=data.category_name//培训班名称
                   form.condition=data.category_status+''//状态
                   form.enableDate=data.start_time//启用日期
                   form.stopDate=data.end_time//停用日期
                   form.peopleNum=data.category_size//每班人数
                   form.outlay=data.tuition_incidentals//学杂费
                   form.tuition=data.tuition//学费
                   form.incidental=data.incidentals//杂费
                   form.explain=data.remark//说明
               }
            })
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        let form = this.form
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        this.$request({
                            url:'/api/classcategory/edit',
                            method:'POST',
                            data:{
                                id:this.$route.query.id,
                                category_name:form.className,//班级类别名称
                                category_size:form.peopleNum,//班级类别人数
                                category_status:form.condition,//状态
                                lecture_number:form.frequency,//授课次数
                                remark:form.explain,//说明
                                course_id:form.subject,//学科ID
                                resources_id:form.principal,//主责老师
                                tuition_incidentals:form.outlay,//学杂费
                                tuition:form.tuition,//学费
                                incidentals:form.incidental,//杂费
                                start_time:form.enableDate,//启用日期
                                end_time:form.stopDate//停用日期
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                  message: '编辑列表成功',
                                  type: 'success'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            },
            
        }
    }
</script>

<style scoped="scoped">
    .EditList {
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
    
    /* 培训科目弹窗 */
    .ruleform ::v-deep .el-input__inner {
        width: 173px;
        height: 33px;
        margin-left: 20px;
    }
    
    ::v-deep .el-tree-node__content {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-left: 10px;
        margin-top: 5px;
    }
    
    ::v-deep .el-tree-node__children .el-tree-node__content {
        color: #333;
    }
    
    ::v-deep .el-dialog__body {
        /* padding: 5px; */
        margin-bottom: 30px;
    }
    
    .el-tree {
        height: 600px !important;
    }
    /* 主责老师弹窗 */
     .el-table {
         margin: 20px;
     }
     
     ::v-deep .el-table__cell {
         color: #000;
         padding: 0px;
         height: 35px;
     }
    ::v-deep .dig .el-dialog{
        width: 900px !important;
        height: 600px !important;
        display: flex !important;
        flex-direction: column !important;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
